import * as React from 'react';

const MontereyQuarterWheel = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 35.573 35.485"
  >
    <path d="M.767 35.369c-.592-.217-.814-.585-.759-1.255.044-.528.246-.743.782-.83.552-.089 1.095-.02 4.759.61.326.055.608.082.628.06.02-.023.063-.233.097-.466.263-1.849 1.807-6.202 3.148-8.882.25-.5.449-.915.44-.922-.008-.007-.88-.512-1.937-1.121-1.056-.61-2.044-1.203-2.194-1.319-.709-.545-.792-1.263-.207-1.791.429-.388.731-.43 1.345-.183.433.173.473.195 2.167 1.179 1.37.797 1.795 1.013 1.99 1.013.114 0 .257-.176 1.129-1.385 1.791-2.487 2.298-3.098 4.402-5.302.746-.782 1.378-1.46 1.404-1.508.055-.1-.366-.676-1.452-1.985-1.261-1.52-1.682-2.156-1.735-2.622-.04-.356.18-.643.704-.918.771-.404.983-.237 3.445 2.704.569.68 1.05 1.235 1.069 1.235.019 0 .192-.126.384-.28 1.697-1.357 7.375-4.661 8.683-5.053.299-.09.3-.09.288-.272-.012-.2-.168-.665-.859-2.549-.664-1.813-.785-2.377-.589-2.75.102-.192.515-.59.72-.693.695-.349 1.278.364 2.08 2.54.803 2.183.943 2.55.997 2.606.073.077.573-.023 1.88-.375a26.993 26.993 0 011.347-.331c.233-.045.474-.093.536-.106l.112-.024V6.78l-.337.087c-.185.049-.831.229-1.435.401C22.513 10.483 13.04 19.629 9.408 30.819c-.442 1.36-.912 3.22-1.089 4.303l-.059.362-3.598-.003c-3.57-.003-3.6-.004-3.895-.112zm16.322-.346c.926-3.43 2.337-6.447 4.299-9.195 2.489-3.486 5.66-6.26 9.527-8.33 1.53-.82 3.868-1.782 4.357-1.793l.274-.006.013 1.149.014 1.15-1.161.518c-2.144.957-2.584 1.179-2.584 1.304 0 .176.169.365 2.007 2.249l1.736 1.779v1.446c0 1.62-.003 1.636-.307 1.48-.09-.048-1.23-1.14-2.533-2.43-1.304-1.29-2.569-2.523-2.812-2.74-.503-.452-.474-.449-.985-.119-.58.375-1.298 1.016-2.623 2.343-1.286 1.287-2.179 2.282-2.938 3.273l-.391.51 3.936 3.937 3.937 3.937h-3.393l-2.87-2.87c-1.577-1.578-2.881-2.869-2.897-2.869-.057 0-1.09 2.178-1.36 2.87-.297.755-.6 1.65-.812 2.398l-.134.47h-2.424z" />
  </svg>
);

export default MontereyQuarterWheel;
