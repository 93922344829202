import '../styles/globals.css';

import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import SEO from './seo';

import MontereyQuarterWheel from './monterey-quarter-wheel';
import Footer from './footer';

const MontereyLogo = () => (
  <svg width="auto.939289mm" viewBox="0 0 49.939289 16.491488">
    <defs id="defs2">
      <rect
        x="20.016005"
        y="44.732784"
        width="71.892265"
        height="30.079317"
        id="rect12"
      />
    </defs>
    <g id="layer1" transform="translate(-31.935424,-44.751108)">
      <g
        ariaLabel="Monterey
Software"
        id="text10"
        style={{
          fontStyle: 'normal',
          fontVariant: 'normal',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontSize: '10.5833px',
          lineHeight: '78%',
          fontFamily: 'sans-serif',
          InkscapeFontSpecification: '"sans-serif Bold"',
          textAlign: 'center',
          letterSpacing: 0,
          wordSpacing: 0,
          whiteSpace: 'pre',
          shapeInside: 'url(#rect12)',
          fill: 'white',
          fillOpacity: 1,
          stroke: 'none',
          strokeWidth: '0.264583px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
      >
        <path
          d="m 35.652149,51.778419 q -0.793748,-2.518825 -1.650995,-4.952984 h -0.08467 q 0,0.116416 -0.04233,0.77258 -0.04233,0.656165 -0.07408,1.09008 -0.126999,1.777995 -0.126999,1.915578 0,0.338665 0.338665,0.338665 l 0.359832,-0.01058 0.0635,0.05292 -0.116416,0.666748 q -0.888997,-0.04233 -1.238246,-0.04233 -0.243416,0 -0.592665,0.03175 -0.338666,0.03175 -0.455082,0.05292 l 0.0635,-0.687914 q 0.306916,-0.03175 0.444499,-0.190499 0.137582,-0.169333 0.201082,-0.571499 0.09525,-0.825497 0.253999,-2.465908 0.169333,-1.640412 0.169333,-1.799161 0,-0.285749 -0.169333,-0.391582 -0.158749,-0.105833 -0.634998,-0.148167 l -0.05292,-0.0635 0.09525,-0.613832 q 0.86783,0.05292 1.322912,0.05292 0.232833,0 1.12183,-0.04233 0.423332,1.492246 1.174746,3.884071 l 0.179916,0.550332 h 0.08467 q 0.0635,-0.211666 0.169332,-0.529165 0.105833,-0.328082 0.22225,-0.698498 0.539748,-1.608661 1.02658,-3.17499 0.222249,0 0.359832,0.01058 l 0.560915,0.01058 0.328082,-0.01058 q 0.222249,0 0.571498,-0.02117 0.349249,-0.02117 0.507999,-0.04233 l 0.04233,0.04233 -0.0635,0.634998 q -0.455082,0.04233 -0.624415,0.15875 -0.158749,0.116416 -0.158749,0.402165 0,0.328082 0.08467,1.672161 0.08467,1.333496 0.169333,2.30716 0.0635,0.582081 0.15875,0.783164 0.105833,0.190499 0.359832,0.190499 0.116416,0 0.190499,-0.01058 0.08467,-0.01058 0.105833,-0.01058 l 0.04233,0.05292 -0.09525,0.666748 q -0.15875,0 -0.285749,-0.01058 -0.465666,-0.02117 -0.783165,-0.02117 -0.306915,0 -0.814914,0.03175 -0.497415,0.03175 -0.687914,0.05292 l 0.07408,-0.687914 q 0.317499,-0.04233 0.455082,-0.15875 0.137583,-0.126999 0.137583,-0.370415 0,-0.04233 -0.08467,-1.312329 -0.116416,-1.661578 -0.137583,-2.338909 h -0.09525 q -0.888997,2.476492 -1.650995,4.857734 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontSize: '10.5833px',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
          }}
          id="path192"
        />
        <path
          d="m 43.419629,51.704336 q -1.185329,0 -1.86266,-0.687915 -0.666748,-0.698498 -0.666748,-1.92616 0,-0.878414 0.349249,-1.523995 0.349248,-0.656165 0.99483,-1.005414 0.656164,-0.349249 1.523995,-0.349249 1.206496,0 1.873244,0.687915 0.666748,0.677331 0.666748,1.904994 0,0.878413 -0.359832,1.534578 -0.349249,0.656165 -1.005414,1.015997 -0.645581,0.349249 -1.513412,0.349249 z m 0.211666,-0.793748 q 0.571498,0 0.878414,-0.423332 0.306916,-0.423332 0.306916,-1.227662 0,-1.079497 -0.338666,-1.619245 -0.328082,-0.539749 -0.952497,-0.539749 -0.560915,0 -0.857247,0.412749 -0.296332,0.412749 -0.296332,1.227663 0,1.09008 0.317499,1.629828 0.328082,0.539748 0.941913,0.539748 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontSize: '10.5833px',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
          }}
          id="path194"
        />
        <path
          d="m 51.265161,47.248767 q -0.01058,-0.201083 -0.07408,-0.275166 -0.0635,-0.07408 -0.211666,-0.07408 H 50.68308 l -0.03175,-0.07408 0.05292,-0.550332 q 0.127,0.01058 0.476249,0.02117 0.349249,0.01058 0.709081,0.01058 0.349249,0 0.656164,-0.01058 0.306916,-0.02117 0.412749,-0.03175 l -0.04233,0.603248 q -0.285749,0.04233 -0.412749,0.158749 -0.116416,0.116417 -0.126999,0.359832 l -0.127,4.307403 -1.047747,0.105833 q -0.80433,-1.185329 -1.513412,-2.158993 -0.698497,-0.984247 -0.888997,-1.248829 h -0.09525 v 2.254243 q 0,0.222249 0.0635,0.306915 0.07408,0.08467 0.243416,0.08467 0.09525,0 0.201083,-0.01058 0.116416,-0.01058 0.15875,-0.01058 l 0.05292,0.05292 -0.09525,0.571498 q -0.126999,0 -0.476248,-0.02117 -0.349249,-0.01058 -0.687915,-0.01058 -0.306915,0 -0.666748,0.02117 -0.349248,0.03175 -0.455081,0.04233 l 0.07408,-0.603248 q 0.264582,-0.03175 0.380998,-0.148166 0.116417,-0.116417 0.127,-0.370416 l 0.116416,-3.153823 q 0,-0.264583 -0.137583,-0.359832 -0.137582,-0.105833 -0.560914,-0.15875 l -0.07408,-0.08467 0.0635,-0.529165 q 0.09525,0.01058 0.433916,0.03175 0.338665,0.01058 0.624414,0.01058 0.306916,0 0.560915,-0.01058 0.253999,-0.01058 0.338666,-0.02117 0.656164,1.02658 1.322912,1.979077 0.677331,0.941914 0.878414,1.206496 h 0.09525 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontSize: '10.5833px',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
          }}
          id="path196"
        />
        <path
          d="m 54.137204,51.058754 q 0.402165,-0.03175 0.582081,-0.09525 0.179916,-0.07408 0.232833,-0.22225 0.0635,-0.158749 0.0635,-0.497415 v -3.196156 q -0.423332,0.01058 -0.613832,0.08467 -0.179916,0.07408 -0.264582,0.285749 -0.08467,0.211666 -0.148167,0.719665 l -0.80433,0.05292 q 0.01058,-0.179916 0.04233,-0.761997 0.04233,-0.592665 0.05292,-1.111247 l 0.105833,-0.105833 0.899581,0.09525 h 2.730491 l 0.920747,-0.09525 0.105833,0.105833 q 0.01058,0.539748 0.03175,1.100663 0.03175,0.560915 0.04233,0.719665 l -0.80433,0.05292 q -0.0635,-0.550331 -0.148167,-0.772581 -0.07408,-0.222249 -0.243416,-0.285749 -0.169332,-0.07408 -0.571498,-0.08467 -0.08467,1.206496 -0.08467,3.397239 0,0.306916 0.105833,0.433915 0.105833,0.127 0.370415,0.127 0.169333,0 0.296333,-0.01058 0.126999,-0.01058 0.158749,-0.01058 l 0.03175,0.04233 -0.07408,0.624414 q -0.179916,-0.01058 -0.656165,-0.03175 -0.476248,-0.01058 -0.910163,-0.01058 -0.306916,0 -0.825498,0.02117 -0.518581,0.03175 -0.687914,0.04233 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontSize: '10.5833px',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
          }}
          id="path198"
        />
        <path
          d="m 58.690503,51.079921 q 0.359832,-0.04233 0.486832,-0.190499 0.126999,-0.15875 0.126999,-0.529165 l 0.01058,-3.016241 q 0,-0.264582 -0.05292,-0.349249 -0.05292,-0.09525 -0.222249,-0.09525 l -0.253999,0.01058 -0.0635,-0.08467 0.07408,-0.539749 q 0.169333,0 0.645581,0.01058 0.486832,0.01058 1.037164,0.01058 1.873244,0 2.666991,-0.09525 l 0.07408,0.09525 q -0.03175,0.137583 -0.105833,0.603248 -0.0635,0.455082 -0.09525,0.941914 l -0.878413,0.05292 q 0,-0.03175 0.01058,-0.126999 0.01058,-0.105833 0.01058,-0.201083 0,-0.232833 -0.07408,-0.328082 -0.07408,-0.09525 -0.285749,-0.127 -0.211666,-0.04233 -0.698498,-0.04233 -0.285749,0 -0.359832,0.04233 -0.0635,0.04233 -0.07408,0.211666 l -0.05292,1.195913 h 0.783165 q 0.190499,0 0.539748,-0.02117 0.349249,-0.03175 0.465665,-0.04233 l 0.08467,0.09525 -0.126999,0.761997 q -0.116416,0 -0.444499,-0.01058 -0.317499,-0.01058 -0.677331,-0.01058 -0.285749,0 -0.656164,0.05292 -0.02117,0.507999 -0.02117,1.037164 0,0.232832 0.03175,0.328082 0.03175,0.09525 0.137583,0.137583 0.105833,0.03175 0.359832,0.03175 0.529165,0 0.772581,-0.0635 0.243416,-0.07408 0.349249,-0.253999 0.105833,-0.1905 0.169332,-0.613832 l 0.804331,-0.0635 0.08467,0.09525 q -0.03175,0.127 -0.116417,0.592665 -0.07408,0.465665 -0.126999,0.984247 l -0.09525,0.09525 q -0.264582,-0.01058 -1.15358,-0.03175 -0.878413,-0.02117 -1.587495,-0.02117 -0.497415,0 -0.96308,0.03175 -0.465665,0.04233 -0.603248,0.05292 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontSize: '10.5833px',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
          }}
          id="path200"
        />
        <path
          d="m 68.254831,51.736086 q -0.613832,0 -0.963081,-0.761998 l -0.709081,-1.386412 h -0.592665 l -0.01058,0.814914 q 0,0.285749 0.04233,0.412749 0.04233,0.116416 0.15875,0.158749 0.126999,0.04233 0.423332,0.05292 l 0.04233,0.05292 -0.09525,0.560915 q -0.169332,-0.01058 -0.603248,-0.02117 -0.423332,0 -0.740831,0 -0.338665,0 -0.698497,0.02117 -0.359833,0.02117 -0.465666,0.03175 l 0.07408,-0.613832 q 0.359833,-0.04233 0.476249,-0.179916 0.127,-0.148166 0.127,-0.497415 l 0.01058,-3.100907 q 0,-0.222249 -0.0635,-0.296332 -0.05292,-0.08467 -0.201083,-0.08467 l -0.306915,0.01058 -0.0635,-0.07408 0.08467,-0.539748 1.12183,0.01058 q 0.370415,0 0.793747,-0.01058 0.423332,-0.02117 0.592665,-0.03175 1.047747,0 1.598078,0.370415 0.550332,0.370416 0.550332,1.079497 0,0.486832 -0.296332,0.888997 -0.296333,0.402165 -0.857248,0.677331 l 0.709081,1.09008 q 0.254,0.370415 0.476249,0.539748 0.222249,0.169333 0.476248,0.169333 l 0.07408,0.09525 -0.07408,0.476248 z m -1.481662,-2.889241 q 0.709081,-0.253999 0.709081,-0.973664 0,-0.423332 -0.306916,-0.624414 -0.306916,-0.211666 -0.941914,-0.211666 -0.08467,0 -0.116416,0.04233 -0.02117,0.03175 -0.03175,0.148166 -0.0635,0.867831 -0.0635,1.534578 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontSize: '10.5833px',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
          }}
          id="path202"
        />
        <path
          d="m 69.976601,51.079921 q 0.359833,-0.04233 0.486832,-0.190499 0.127,-0.15875 0.127,-0.529165 l 0.01058,-3.016241 q 0,-0.264582 -0.05292,-0.349249 -0.05292,-0.09525 -0.22225,-0.09525 l -0.253999,0.01058 -0.0635,-0.08467 0.07408,-0.539749 q 0.169333,0 0.645582,0.01058 0.486831,0.01058 1.037163,0.01058 1.873244,0 2.666992,-0.09525 l 0.07408,0.09525 q -0.03175,0.137583 -0.105833,0.603248 -0.0635,0.455082 -0.09525,0.941914 l -0.878414,0.05292 q 0,-0.03175 0.01058,-0.126999 0.01058,-0.105833 0.01058,-0.201083 0,-0.232833 -0.07408,-0.328082 -0.07408,-0.09525 -0.28575,-0.127 -0.211665,-0.04233 -0.698497,-0.04233 -0.285749,0 -0.359832,0.04233 -0.0635,0.04233 -0.07408,0.211666 l -0.05292,1.195913 h 0.783164 q 0.1905,0 0.539748,-0.02117 0.349249,-0.03175 0.465666,-0.04233 l 0.08467,0.09525 -0.127,0.761997 q -0.116416,0 -0.444498,-0.01058 -0.317499,-0.01058 -0.677331,-0.01058 -0.285749,0 -0.656165,0.05292 -0.02117,0.507999 -0.02117,1.037164 0,0.232832 0.03175,0.328082 0.03175,0.09525 0.137583,0.137583 0.105833,0.03175 0.359832,0.03175 0.529165,0 0.772581,-0.0635 0.243416,-0.07408 0.349249,-0.253999 0.105833,-0.1905 0.169333,-0.613832 l 0.804331,-0.0635 0.08467,0.09525 q -0.03175,0.127 -0.116416,0.592665 -0.07408,0.465665 -0.127,0.984247 l -0.09525,0.09525 q -0.264583,-0.01058 -1.15358,-0.03175 -0.878414,-0.02117 -1.587495,-0.02117 -0.497415,0 -0.96308,0.03175 -0.465665,0.04233 -0.603248,0.05292 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontSize: '10.5833px',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
          }}
          id="path204"
        />
        <path
          d="m 76.012556,51.079921 q 0.349249,-0.04233 0.518582,-0.105833 0.169332,-0.07408 0.222249,-0.211666 0.0635,-0.148166 0.0635,-0.423332 v -0.222249 q 0,-0.201083 -0.05292,-0.380999 -0.05292,-0.179916 -0.190499,-0.433915 L 75.695057,47.714432 Q 75.504558,47.3546 75.303475,47.185267 75.102392,47.015934 74.731977,46.920684 l -0.03175,-0.04233 0.08467,-0.582081 q 0.137583,0 0.486832,0.01058 0.349249,0.01058 0.677331,0.01058 0.275166,0 0.761998,-0.02117 0.497415,-0.03175 0.656164,-0.04233 l 0.05292,0.08467 -0.05292,0.518581 q -0.275165,0.01058 -0.380998,0.0635 -0.105833,0.05292 -0.105833,0.179916 0,0.09525 0.222249,0.571499 0.222249,0.476248 0.264582,0.571498 l 0.201083,0.423332 h 0.08467 l 0.624415,-1.24883 q 0.137583,-0.243416 0.137583,-0.380998 0,-0.08467 -0.105833,-0.127 -0.09525,-0.04233 -0.317499,-0.05292 l -0.03175,-0.04233 0.0635,-0.529165 0.497415,0.01058 q 0.465665,0.02117 0.709081,0.02117 0.317499,0 0.571498,-0.01058 0.264583,-0.01058 0.349249,-0.02117 l 0.03175,0.0635 -0.0635,0.550332 q -0.338665,0.116416 -0.560915,0.349249 -0.222249,0.232832 -0.486831,0.698497 l -0.825498,1.481662 q -0.105833,0.201083 -0.126999,0.402166 -0.02117,0.190499 -0.03175,0.645581 0,0.296332 0.08467,0.412749 0.09525,0.116416 0.338666,0.116416 0.148166,0 0.264582,-0.01058 0.127,-0.01058 0.169333,-0.01058 l 0.03175,0.04233 -0.08467,0.624414 q -0.169332,-0.01058 -0.624414,-0.03175 -0.455082,-0.01058 -0.888997,-0.01058 -0.317499,0 -0.804331,0.03175 -0.476249,0.04233 -0.624415,0.05292 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontSize: '10.5833px',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontVariantNumeric: 'normal',
            fontVariantEastAsian: 'normal',
          }}
          id="path206"
        />
        <path
          d="m 33.872168,60.131349 q -0.486832,0 -0.89958,-0.09525 -0.412749,-0.08467 -0.687915,-0.1905 -0.264582,-0.105833 -0.264582,-0.116416 l -0.08467,-0.148166 q 0.01058,-0.07408 0.04233,-0.317499 0.04233,-0.253999 0.08467,-0.666748 0.04233,-0.423332 0.04233,-0.814914 l 0.867831,-0.0635 q 0,0.592665 0.105833,0.910164 0.105833,0.317499 0.349249,0.455082 0.253999,0.126999 0.719664,0.126999 0.507998,0 0.814914,-0.222249 0.306916,-0.222249 0.306916,-0.613831 0,-0.349249 -0.328083,-0.592665 -0.317499,-0.243416 -0.99483,-0.571498 -0.04233,-0.02117 -0.243416,-0.116417 -0.793747,-0.380998 -1.238246,-0.80433 -0.444498,-0.423332 -0.444498,-1.100664 0,-0.603248 0.359832,-1.079496 0.359832,-0.486832 0.99483,-0.751414 0.645581,-0.275166 1.460495,-0.275166 0.465666,0 0.857248,0.0635 0.402165,0.0635 0.634998,0.137582 0.243416,0.0635 0.306915,0.08467 l 0.07408,0.137583 q -0.01058,0.0635 -0.0635,0.328082 -0.05292,0.253999 -0.105833,0.656165 -0.05292,0.402165 -0.08467,0.825497 l -0.783164,0.0635 -0.09525,-0.0635 q 0,-0.03175 0.01058,-0.127 0.01058,-0.105833 0.01058,-0.253999 0,-0.476248 -0.243416,-0.677331 -0.232832,-0.201083 -0.793747,-0.201083 -0.455082,0 -0.751415,0.22225 -0.285749,0.211666 -0.285749,0.550331 0,0.253999 0.179916,0.465665 0.1905,0.201083 0.444499,0.349249 0.264582,0.148166 0.761998,0.391582 0.592664,0.285749 0.973663,0.518582 0.391582,0.232833 0.656165,0.592665 0.264582,0.359832 0.264582,0.857247 0,0.634998 -0.370415,1.12183 -0.359832,0.476248 -1.02658,0.740831 -0.656165,0.264582 -1.534579,0.264582 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            textAlign: 'center',
            letterSpacing: '0.264583px',
            textAnchor: 'middle',
          }}
          id="path208"
        />
        <path
          d="m 40.360393,60.099599 q -1.18533,0 -1.862661,-0.687914 -0.666748,-0.698498 -0.666748,-1.926161 0,-0.878413 0.349249,-1.523995 0.349249,-0.656164 0.99483,-1.005413 0.656165,-0.349249 1.523996,-0.349249 1.206496,0 1.873244,0.687914 0.666747,0.677332 0.666747,1.904994 0,0.878414 -0.359832,1.534579 -0.349249,0.656164 -1.005413,1.015997 -0.645581,0.349248 -1.513412,0.349248 z m 0.211666,-0.793747 q 0.571498,0 0.878414,-0.423332 0.306916,-0.423332 0.306916,-1.227663 0,-1.079496 -0.338666,-1.619245 -0.328082,-0.539748 -0.952497,-0.539748 -0.560915,0 -0.857247,0.412749 -0.296333,0.412748 -0.296333,1.227662 0,1.09008 0.317499,1.629829 0.328083,0.539748 0.941914,0.539748 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            textAlign: 'center',
            letterSpacing: '0.264583px',
            textAnchor: 'middle',
          }}
          id="path210"
        />
        <path
          d="m 44.258355,59.475185 q 0.359832,-0.05292 0.486831,-0.201083 0.127,-0.158749 0.127,-0.518582 l 0.01058,-3.01624 q 0,-0.264583 -0.05292,-0.349249 -0.05292,-0.09525 -0.222249,-0.09525 l -0.254,0.01058 -0.0635,-0.08467 0.07408,-0.539748 q 0.169332,0 0.645581,0.01058 0.486832,0.01058 1.037163,0.01058 1.714495,0 2.444743,-0.09525 l 0.08467,0.09525 q -0.02117,0.137582 -0.08467,0.613831 -0.0635,0.465665 -0.09525,0.93133 l -0.867831,0.05292 0.01058,-0.328082 q 0,-0.22225 -0.0635,-0.317499 -0.0635,-0.105833 -0.243416,-0.137583 -0.169333,-0.04233 -0.560915,-0.04233 -0.285749,0 -0.359832,0.04233 -0.0635,0.04233 -0.07408,0.211666 l -0.0635,1.333495 0.793747,0.01058 q 0.169333,0 0.486832,-0.02117 0.328082,-0.03175 0.433915,-0.04233 l 0.07408,0.08467 -0.116417,0.772581 q -0.105833,0 -0.412748,-0.01058 -0.296333,-0.01058 -0.624415,-0.01058 -0.1905,0 -0.391582,0.02117 -0.201083,0.02117 -0.264583,0.02117 l -0.02117,0.86783 q 0,0.317499 0.07408,0.423332 0.07408,0.105833 0.296332,0.105833 0.137583,0 0.317499,-0.02117 0.179916,-0.02117 0.232833,-0.03175 l 0.09525,0.105833 -0.105833,0.698498 q -0.148166,0 -0.560914,-0.02117 -0.412749,-0.01058 -0.804331,-0.01058 -0.444499,0 -0.899581,0.03175 -0.455082,0.04233 -0.582081,0.05292 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            textAlign: 'center',
            letterSpacing: '0.264583px',
            textAnchor: 'middle',
          }}
          id="path212"
        />
        <path
          d="m 50.036175,59.454018 q 0.402165,-0.03175 0.582081,-0.09525 0.179916,-0.07408 0.232833,-0.222249 0.0635,-0.158749 0.0635,-0.497415 v -3.196156 q -0.423332,0.01058 -0.613832,0.08467 -0.179916,0.07408 -0.264582,0.285749 -0.08467,0.211666 -0.148166,0.719664 l -0.804331,0.05292 q 0.01058,-0.179916 0.04233,-0.761998 0.04233,-0.592664 0.05292,-1.111246 l 0.105833,-0.105833 0.89958,0.09525 h 2.730491 l 0.920747,-0.09525 0.105833,0.105833 q 0.01058,0.539748 0.03175,1.100663 0.03175,0.560915 0.04233,0.719664 l -0.804331,0.05292 q -0.0635,-0.550332 -0.148166,-0.772581 -0.07408,-0.222249 -0.243416,-0.285749 -0.169333,-0.07408 -0.571498,-0.08467 -0.08467,1.206496 -0.08467,3.397239 0,0.306915 0.105833,0.433915 0.105833,0.127 0.370416,0.127 0.169332,0 0.296332,-0.01058 0.127,-0.01058 0.15875,-0.01058 l 0.03175,0.04233 -0.07408,0.624415 q -0.179916,-0.01058 -0.656164,-0.03175 -0.476249,-0.01058 -0.910164,-0.01058 -0.306916,0 -0.825497,0.02117 -0.518582,0.03175 -0.687915,0.04233 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            textAlign: 'center',
            letterSpacing: '0.264583px',
            textAnchor: 'middle',
          }}
          id="path214"
        />
        <path
          d="m 56.282803,60.131349 q -0.126999,-0.486831 -0.529165,-1.979077 -0.391582,-1.492245 -0.666748,-2.349492 -0.08467,-0.253999 -0.222249,-0.370416 -0.137583,-0.126999 -0.370415,-0.148166 -0.02117,-0.04233 -0.04233,-0.0635 l 0.07408,-0.539748 q 0.116417,0 0.423332,0.01058 0.317499,0.01058 0.656165,0.01058 0.444499,0 0.888997,-0.02117 0.444499,-0.02117 0.571498,-0.03175 l 0.04233,0.0635 -0.04233,0.550331 q -0.296332,0.03175 -0.423332,0.0635 -0.126999,0.02117 -0.169332,0.07408 -0.04233,0.05292 -0.04233,0.169333 0,0.201083 0.296333,1.502829 0.306916,1.301746 0.423332,1.767411 h 0.126999 q 0.116417,-0.264583 0.402166,-1.206496 0.285749,-0.941914 0.497415,-1.703912 -0.01058,-0.148166 -0.05292,-0.306915 -0.04233,-0.1905 -0.169332,-0.254 -0.127,-0.07408 -0.444499,-0.08467 l -0.04233,-0.0635 0.07408,-0.539748 q 0.137583,0 0.497415,0.01058 0.370415,0.01058 0.740831,0.01058 0.423332,0 0.867831,-0.02117 0.455081,-0.02117 0.582081,-0.03175 l 0.05292,0.0635 -0.04233,0.550331 q -0.306915,0.03175 -0.433915,0.0635 -0.116416,0.02117 -0.158749,0.07408 -0.04233,0.05292 -0.04233,0.169333 0,0.1905 0.296333,1.502829 0.296332,1.301746 0.412748,1.767411 h 0.137583 q 0.148166,-0.349249 0.571498,-1.746245 0.423332,-1.407579 0.423332,-1.598078 0,-0.116416 -0.08467,-0.158749 -0.08467,-0.05292 -0.317499,-0.05292 h -0.306916 l -0.04233,-0.0635 0.0635,-0.550331 q 0.127,0.01058 0.476248,0.02117 0.359833,0.01058 0.772581,0.01058 l 0.994831,-0.01058 -0.0635,0.592665 q -0.179916,0.02117 -0.275166,0.105833 -0.08467,0.08467 -0.15875,0.317499 l -1.471078,4.254487 -1.566329,0.169332 Q 59.32021,59.284685 58.780462,57.337358 h -0.08467 l -0.846664,2.624659 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            textAlign: 'center',
            letterSpacing: '0.264583px',
            textAnchor: 'middle',
          }}
          id="path216"
        />
        <path
          d="m 65.782142,60.00435 0.04233,-0.550332 q 0.306916,-0.03175 0.433915,-0.05292 0.127,-0.03175 0.169333,-0.08467 0.05292,-0.05292 0.05292,-0.158749 0,-0.116417 -0.04233,-0.243416 L 66.29014,58.363938 q -0.222249,-0.01058 -0.772581,-0.01058 -0.243416,0 -0.804331,0.05292 l -0.190499,0.550332 q -0.04233,0.15875 -0.04233,0.232833 0,0.137583 0.07408,0.190499 0.08467,0.04233 0.306916,0.04233 h 0.328082 l 0.05292,0.07408 -0.0635,0.550332 q -0.127,-0.01058 -0.539749,-0.03175 -0.402165,-0.01058 -0.751414,-0.01058 -0.370415,0 -0.677331,0.01058 -0.296332,0.01058 -0.391582,0.01058 l 0.0635,-0.592664 q 0.190499,-0.03175 0.306915,-0.148167 0.127,-0.126999 0.232833,-0.370415 l 1.523995,-4.16982 1.555745,-0.137583 0.105833,0.0635 q 0.127,0.476248 0.539748,1.947327 0.412749,1.471078 0.677332,2.254243 0.08467,0.285749 0.222249,0.412748 0.148166,0.127 0.423332,0.148167 l 0.04233,0.0635 -0.0635,0.529165 q -0.116416,0 -0.444498,-0.01058 -0.317499,-0.01058 -0.656165,-0.01058 -0.476248,0 -0.93133,0.02117 -0.455082,0.03175 -0.582082,0.04233 z m -0.264583,-2.391826 q 0.349249,0 0.613832,-0.03175 L 65.655142,55.86628 h -0.126999 l -0.582082,1.746244 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            textAlign: 'center',
            letterSpacing: '0.264583px',
            textAnchor: 'middle',
          }}
          id="path218"
        />
        <path
          d="m 73.42246,60.131349 q -0.613832,0 -0.963081,-0.761997 l -0.709081,-1.386413 h -0.592665 l -0.01058,0.814915 q 0,0.285749 0.04233,0.412748 0.04233,0.116417 0.15875,0.15875 0.126999,0.04233 0.423332,0.05292 l 0.04233,0.05292 -0.09525,0.560915 q -0.169332,-0.01058 -0.603248,-0.02117 -0.423332,0 -0.740831,0 -0.338665,0 -0.698498,0.02117 -0.359832,0.02117 -0.465665,0.03175 l 0.07408,-0.613831 q 0.359833,-0.04233 0.476249,-0.179916 0.126999,-0.148166 0.126999,-0.497415 l 0.01058,-3.100907 q 0,-0.222249 -0.0635,-0.296332 -0.05292,-0.08467 -0.201083,-0.08467 l -0.306915,0.01058 -0.0635,-0.07408 0.08467,-0.539749 1.12183,0.01058 q 0.370415,0 0.793747,-0.01058 0.423332,-0.02117 0.592665,-0.03175 1.047747,0 1.598078,0.370416 0.550332,0.370415 0.550332,1.079496 0,0.486832 -0.296332,0.888998 -0.296333,0.402165 -0.857248,0.677331 l 0.709081,1.09008 q 0.254,0.370415 0.476249,0.539748 0.222249,0.169333 0.476248,0.169333 l 0.07408,0.09525 -0.07408,0.476249 z m -1.481662,-2.889241 q 0.709081,-0.253999 0.709081,-0.973663 0,-0.423332 -0.306916,-0.624415 -0.306916,-0.211666 -0.941914,-0.211666 -0.08467,0 -0.116416,0.04233 -0.02117,0.03175 -0.03175,0.148167 -0.0635,0.86783 -0.0635,1.534578 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            textAlign: 'center',
            letterSpacing: '0.264583px',
            textAnchor: 'middle',
          }}
          id="path220"
        />
        <path
          d="m 75.408812,59.475185 q 0.359832,-0.04233 0.486832,-0.1905 0.126999,-0.158749 0.126999,-0.529165 l 0.01058,-3.01624 q 0,-0.264583 -0.05292,-0.349249 -0.05292,-0.09525 -0.222249,-0.09525 l -0.253999,0.01058 -0.0635,-0.08467 0.07408,-0.539748 q 0.169333,0 0.645581,0.01058 0.486832,0.01058 1.037164,0.01058 1.873244,0 2.666991,-0.09525 l 0.07408,0.09525 q -0.03175,0.137582 -0.105833,0.603248 -0.0635,0.455082 -0.09525,0.941913 l -0.878414,0.05292 q 0,-0.03175 0.01058,-0.127 0.01058,-0.105833 0.01058,-0.201082 0,-0.232833 -0.07408,-0.328083 -0.07408,-0.09525 -0.285749,-0.126999 -0.211666,-0.04233 -0.698498,-0.04233 -0.285749,0 -0.359832,0.04233 -0.0635,0.04233 -0.07408,0.211666 l -0.05292,1.195913 h 0.783164 q 0.190499,0 0.539748,-0.02117 0.349249,-0.03175 0.465665,-0.04233 l 0.08467,0.09525 -0.127,0.761998 q -0.116416,0 -0.444499,-0.01058 -0.317499,-0.01058 -0.677331,-0.01058 -0.285749,0 -0.656164,0.05292 -0.02117,0.507998 -0.02117,1.037163 0,0.232833 0.03175,0.328083 0.03175,0.09525 0.137583,0.137582 0.105833,0.03175 0.359832,0.03175 0.529165,0 0.772581,-0.0635 0.243416,-0.07408 0.349249,-0.254 0.105833,-0.190499 0.169333,-0.613831 l 0.80433,-0.0635 0.08467,0.09525 q -0.03175,0.126999 -0.116417,0.592665 -0.07408,0.465665 -0.126999,0.984247 l -0.09525,0.09525 q -0.264582,-0.01058 -1.153579,-0.03175 -0.878414,-0.02117 -1.587495,-0.02117 -0.497415,0 -0.963081,0.03175 -0.465665,0.04233 -0.603248,0.05292 z"
          style={{
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontFamily: '"Alegreya SC"',
            InkscapeFontSpecification: '"Alegreya SC Bold"',
            textAlign: 'center',
            letterSpacing: '0.264583px',
            textAnchor: 'middle',
          }}
          id="path222"
        />
      </g>
      <text
        xmlSpace="preserve"
        style={{
          fontSize: '10.5833px',
          lineHeight: '125%',
          fontFamily: 'sans-serif',
          textAlign: 'center',
          letterSpacing: 0,
          wordSpacing: 0,
          textAnchor: 'middle',
          fill: 'none',
          stroke: '#000000',
          strokeWidth: '0.264583px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
        x="-115.46826"
        y="19.159201"
        id="text18"
      >
        <tspan
          id="tspan16"
          x="-115.46826"
          y="19.159201"
          style={{ strokeWidth: '0.264583px' }}
        />
      </text>
    </g>
  </svg>
);

const Layout = ({
  headTitle,
  metaDescription,
  children,
  seoTitle,
  seoDescription,
  seoImage,
  seoArticle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{headTitle}</title>
        <meta name="description" content={metaDescription} />
        <body className="text-gray-900 bg-green-50" />
      </Helmet>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        article={seoArticle}
      />
      <nav className="bg-teal-800">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-end">
              <div className="flex-shrink-0 inline">
                <div className="flex items-end text-4xl font-semibold text-white caps-small">
                  <a
                    className="w-10 mr-4 text-white"
                    href="/"
                    aria-label="Home"
                  >
                    <MontereyQuarterWheel />
                  </a>
                  <div style={{ width: '125px' }}>
                    <a href="/" aria-label="Home">
                      <MontereyLogo />
                    </a>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-6">
                <div className="flex pb-1">
                  <Link
                    to="/about-erp/"
                    className="px-3 py-2 font-medium font-semibold leading-5 text-white transition duration-150 ease-in-out rounded-md focus:outline-none focus:ring focus:ring-teal-600 focus:text-white hover:bg-teal-700 focus:bg-teal-700"
                    activeClassName="bg-teal-700 text-white outline-none"
                  >
                    What is ERP?
                  </Link>
                  <Link
                    to="/about-odoo/"
                    className="px-3 py-2 ml-4 font-medium font-semibold leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-teal-700 focus:outline-none focus:ring focus:ring-teal-600 focus:text-white focus:bg-teal-700"
                    activeClassName="bg-teal-700 text-white outline-none"
                  >
                    About Odoo
                  </Link>
                  <Link
                    to="/what-we-do/"
                    className="px-3 py-2 ml-4 font-medium font-semibold leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-teal-700 focus:outline-none focus:ring focus:ring-teal-600 focus:text-white focus:bg-teal-700"
                    activeClassName="bg-teal-700 text-white outline-none"
                  >
                    Pricing
                  </Link>
                  <Link
                    to="/blog/"
                    className="px-3 py-2 ml-4 font-medium font-semibold leading-5 text-gray-300 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-teal-700 focus:outline-none focus:ring focus:ring-teal-600 focus:text-white focus:bg-teal-700"
                    activeClassName="bg-teal-700 text-white outline-none"
                  >
                    Blog
                  </Link>
                </div>
              </div>
            </div>
            <div className="hidden md:ml-6 md:block">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <div className="rounded-md shadow">
                    <Link
                      to="/get-a-demo/"
                      className="flex items-center justify-center w-full mx-2 my-1 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-teal-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:ring-purple md:py-2 md:text-lg md:px-5"
                      style={{ backgroundColor: '#875A7B' }}
                    >
                      Try Odoo
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex -mr-2 md:hidden">
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-teal-700 focus:outline-none focus:bg-teal-700 focus:text-white"
                aria-label="Main menu"
                aria-expanded="false"
              >
                <svg
                  className={`${isOpen ? 'hidden' : 'block'} w-6 h-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`${isOpen ? 'block' : 'hidden'} w-6 h-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
          <div className="px-2 pt-2 pb-3">
            <Link
              to="/about-erp/"
              className="block px-3 py-2 text-base font-medium text-white transition duration-150 ease-in-out rounded-md focus:outline-none focus:ring-gray-500 focus:ring focus:text-white focus:bg-gray-700"
              activeClassName="bg-gray-900 text-white"
            >
              What is ERP?
            </Link>
            <Link
              to="/about-odoo/"
              className="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-gray-500 focus:ring focus:text-white focus:bg-gray-700"
              activeClassName="bg-gray-900 text-white"
            >
              About Odoo
            </Link>
            <Link
              to="/what-we-do/"
              className="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-gray-500 focus:ring focus:text-white focus:bg-gray-700"
              activeClassName="bg-gray-900 text-white"
            >
              Pricing
            </Link>
            <Link
              to="/blog/"
              className="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-gray-500 focus:ring focus:text-white focus:bg-gray-700"
              activeClassName="bg-gray-900 text-white"
            >
              Blog
            </Link>
            <hr />
            <Link
              to="/get-a-demo/"
              className="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-gray-500 focus:ring focus:text-white focus:bg-gray-700"
              activeClassName="bg-gray-900 text-white"
            >
              Try Odoo
            </Link>
          </div>
        </div>
      </nav>
      {children}
      <Footer bgcolor="bg-teal-50" />
    </div>
  );
};

Layout.propTypes = {
  headTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Layout.defaultProps = {
  headTitle: '',
  metaDescription: '',
};

export default Layout;
